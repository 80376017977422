//@extend-elements
//original selectors
//blockquote .glyphicon, blockquote .fa
%extend_1 {
  float: left;
  margin-right: 10px;
  font-size: 20px;
}

//original selectors
//.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default
%extend_2 {
  background: $color_black_haze_approx;
  border-color: $color_celeste_approx;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
}

//original selectors
//.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning
%extend_3 {
  color: $white;
  background-color: $color_fire_bush_approx;
  border-color: $color_meteor_approx;
}

//original selectors
//.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger
%extend_4 {
  color: $white;
  background-color: $color_persian_red_approx;
  border-color: $color_mexican_red_approx;
}

//original selectors
//.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open .dropdown-toggle.btn-info
%extend_5 {
  color: $white;
  background-color: $color_scooter_approx;
  border-color: $color_eastern_blue_approx;
}

//original selectors
//.btn-brown:hover, .btn-browm:active, .btn-brown:focus
%extend_6 {
  color: $white;
  background-color: $color_metallic_bronze_approx;
  border-color: $color_metallic_bronze_approx;
}

//original selectors
//.btn-black:hover, .btn-black:active, .btn-black:focus
%extend_7 {
  background: $color_log_cabin_approx;
  border-color: $color_woodsmoke_approx;
  color: $white;
}

//original selectors
//.btn-pink:hover, .btn-pink:active, .btn-pink:focus
%extend_8 {
  background: $color_orchid_approx;
  border-color: $color_orchid_approx;
  color: $white;
}

//original selectors
//.btn-darkblue:hover, .btn-darkblue:active, .btn-darkblue:focus
%extend_9 {
  background: $color_astronaut_approx;
  border-color: $color_rhino_approx;
  color: $white;
}

//original selectors
//.btn-orange:hover, .btn-orange:active, .btn-orange:focus
%extend_10 {
  background: $color_mango_tango_approx;
  border-color: $color_bamboo_approx;
  color: $white;
}

//original selectors
//.btn-maroon:hover, .btn-maroon:active, .btn-maroon:focus
%extend_11 {
  background: $color_red_berry_approx;
  border-color: $color_maroon_approx;
  color: $white;
}

//original selectors
//.btn-lightblue:hover, .btn-lightblue:active, .btn-lightblue:focus
%extend_12 {
  background: $color_azure_radiance_approx;
  border-color: $color_azure_radiance_approx;
  color: $white;
}

//original selectors
//.btn-link:hover, .btn-link:focus
%extend_13 {
  color: $color_rangoon_green_approx;
  text-decoration: underline;
  background-color: transparent;
}

//original selectors
//.panel-heading .nav-tabs li a:hover, .panel-heading .nav-tabs li a:active, .panel-heading .nav-tabs li a:focus
%extend_14 {
  background-color: $white;
  border-color: $white;
  border-bottom: 0;
  color: $color_woodsmoke_approx;
}

//original selectors
//.panel-heading .nav-tabs li.active a:hover, .panel-heading .nav-tabs li.active a:active, .panel-heading .nav-tabs li.active a:focus
%extend_15 {
  background: $color_alabaster_approx;
  color: $color_woodsmoke_approx;
  border: 1px solid $color_alto_approx;
  border-bottom-color: $color_alabaster_approx;
}

//original selectors
//.nav .open >a, .nav .open >a:hover, .nav .open >a:focus
%extend_16 {
  border: 0;
  background: none;
  color: $color_mine_shaft_approx;
  font-weight: bold;
}

//original selectors
//.nav-line li.active a, .nav-line li.active a:active, .nav-line li.active a:focus
%extend_17 {
  background: none;
  color: $color_boston_blue_approx;
  border-bottom: 5px solid $color_boston_blue_approx;
}

//original selectors
//.nav-top >li >a:hover, .nav-top >li >a:focus, .nav-top >li >a:active, .nav-top >li.btn-group.open >a
%extend_18 {
  background: none;
  color: $white;
  outline: none;
}

//original selectors
//.head-notif:hover, .head-notif:focus, .head-notif:active, .noti-menu.open .head-notif
%extend_19 {
  color: $white;
  background: $color_cinnabar_approx;
  text-decoration: none;
}

//original selectors
//.select2-container .select2-choice, .select2-container-multi .select2-choice, .select2-container .select2-choices, .select2-container-multi .select2-choices
%extend_20 {
  padding: 6px 0 6px 8px;
  background: $white;
  height: auto;
  border-color: $color_celeste_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px;
}

//original selectors
//.select2-container .select2-choice .select2-arrow, .select2-container-multi .select2-choice .select2-arrow, .select2-container .select2-choices .select2-arrow, .select2-container-multi .select2-choices .select2-arrow
%extend_21 {
  border: none;
  background: none;
  outline: none;
}

//original selectors
//.select2-container .select2-choices .select2-search-choice, .select2-container-multi .select2-choices .select2-search-choice
%extend_22 {
  background: $mercury;
  border: 1px solid $color_alto_approx;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
  padding: 5px 10px 5px 24px;
  color: $color_mountain_mist_approx;
  margin: 0 0 0 5px;
}

//original selectors
//.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open >.dropdown-toggle.btn-default
%extend_23 {
  color: $color_mine_shaft_approx;
  background-color: $color_mercury_approx;
  border-color: $color_silver_chalice_approx;
}

//original selectors
//.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open >.dropdown-toggle.btn-primary
%extend_24 {
  color: $white;
  background-color: $color_astral_approx;
  border-color: $color_st_tropaz_approx;
}

//original selectors
//.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open >.dropdown-toggle.btn-success
%extend_25 {
  color: $white;
  background-color: $color_apple_approx;
  border-color: $color_vida_loca_approx;
}

//original selectors
//.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open >.dropdown-toggle.btn-info
%extend_26 {
  color: $white;
  background-color: $color_scooter_approx;
  border-color: $color_eastern_blue_approx;
}

//original selectors
//.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .open >.dropdown-toggle.btn-warning
%extend_27 {
  color: $white;
  background-color: $color_carrot_orange_approx;
  border-color: $color_meteor_approx;
}

//original selectors
//.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open >.dropdown-toggle.btn-danger
%extend_28 {
  color: $white;
  background-color: $color_persian_red_approx;
  border-color: $color_mexican_red_approx;
}