// CHART 1
.chart-container {
  padding: 20px 30px;

  .box-group {
    border-collapse: separate;
    padding: 0;
    margin: 0;
    width: 100%;

    .box {
      position: relative;
      display: table-cell;
      width: 1%;

      .box-group {
        padding-top: 60px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          height: 30px;
          width: $border-size;
          background-color: $border-color;
          top: 0;
          left: 50%;
        }
      }

      .box-inner {
        display: block;
        width: 150px;
        margin: auto;
        border: $border-size solid $border-color;
        background-color: $gray-lighter;
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        font-weight: 500;
        letter-spacing: -0.5px;

        &:before {
          content: '';
          width: $border-size;
          height: 30px;
          background-color: $border-color;
          position: absolute;
          top: -30px;
          left: 50%;
        }

        &:after {
          content: '';
          position: absolute;
          top: -30px;
          left: 0;
          right: 0;
          background-color: $border-color;
          height: $border-size;
        }
      }

      &:first-child > .box-inner:after { left: 50%; }
      &:last-child > .box-inner:after { right: 50%; }
    }
  }

  > .box-group > .box > .box-inner:before { display: none; }
}


// CHART 2
.chart2-container {
  padding: 20px 30px;

  .box-group {
    list-style: none;
    position: relative;

    .box {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -20px;
        bottom: 0;
        width: $border-size2;
        background-color: $border-color2;
      }

      .box-inner {
        display: inline-block;
        border: 1px solid $gray-light;
        padding: 10px 15px;
        border-radius: 3px;
        background-color: $gray-lighter;
        margin-top: 10px;
        font-weight: 500;
        letter-spacing: -0.5px;

        &:before {
          content: '';
          position: absolute;
          top: 30px;
          left: -20px;
          width: 20px;
          height: $border-size2;
          background-color: $border-color2;
        }

        .toggle-icon {
          width: 16px;
        }
      }

      > .box-group {
        .box:last-child::before {
          height: 30px;
        }
      }

      &.load-more {
        .box-inner {
          background-color: #fff;
          font-size: 11px;
          text-transform: uppercase;
          font-weight: 700;
          padding-left: 15px;
          padding-right: 20px;
          border-style: dashed;
          color: $gray-light;

          > i {
            font-size: 14px;
            margin-right: 5px;
            vertical-align: middle;
            display: inline-block;
          }
        }
      }
    }
  }

  > .box-group {
    padding: 0;
    margin: 0;
    border-left: 0;

    > .box {
      margin-top: 0;
      &:before { display: none; }
      > .box-inner:before { display: none; }
    }
  }

  .toggle-down {
    margin-right: 10px;
    font-size: 12px;
    color: $gray-light;
  }

  .user-count {
    margin-left: 20px;
    color: $gray-dark;
    font-size: 13px;
    //line-height: 0;
    border-left: 1px solid darken($gray-lighter, 20%);
    padding-left: 12px;
    display: inline-block;

    > i {
      font-size: 14px;
      line-height: 0;
      margin-right: 5px;
      color: $gray-light;
    }
  }
}