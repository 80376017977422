.select2-dropdown-open {
  &.select2-container {
    .select2-choice {
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 3px 3px 0 0;
      .select2-arrow b {
        background-position: -48px 15px;
      }
    }
    .select2-choices {
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 3px 3px 0 0;
      .select2-arrow b {
        background-position: -48px 15px;
      }
    }
  }
  &.select2-container-multi {
    .select2-choice {
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 3px 3px 0 0;
      .select2-arrow b {
        background-position: -48px 15px;
      }
    }
    .select2-choices {
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 3px 3px 0 0;
      .select2-arrow b {
        background-position: -48px 15px;
      }
    }
  }
}