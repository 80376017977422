.panel {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px;
  border: 0;
  .panel-heading {
    margin: 0;
    &:after {
      clear: both;
      display: block;
      content: '';
    }
  }
}
.panel-heading {
  background: $white;
  border-color: $color_gallery_approx;
  padding: 20px;
  p {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 0;
    color: $color_slate_gray_approx;
  }
  .nav-tabs {
    margin-bottom: -20px;
    border-bottom: 0;
    li {
      margin-right: 5px;
      a {
        color: $color_storm_dust_approx;
        &:hover {
          @extend %extend_14;
        }
        &:active {
          @extend %extend_14;
        }
        &:focus {
          @extend %extend_14;
        }
      }
      &.active a {
        background: $color_alabaster_approx;
        color: $color_woodsmoke_approx;
        &:hover {
          @extend %extend_15;
        }
        &:active {
          @extend %extend_15;
        }
        &:focus {
          @extend %extend_15;
        }
      }
    }
  }
  .nav-justified {
    margin-bottom: -21px;
    li a {
      border-bottom: 0;
    }
  }
}
.panel-footer {
  background: $white;
  border-color: $color_gallery_approx;
  padding: 20px;
  &:after {
    clear: both;
    display: block;
    content: '';
  }
}
.panel-heading-empty {
  background: none;
  border-bottom: 0;
}
.panel-primary {
  .panel-heading {
    border-bottom: 0;
    p {
      color: $white;
      opacity: 0.6;
    }
  }
  .panel-title {
    color: $white;
  }
}
.panel-success {
  .panel-heading {
    border-bottom: 0;
    background-color: $color_mountain_meadow_approx;
    p {
      color: $white;
      opacity: 0.6;
    }
  }
  .panel-title {
    color: $white;
  }
  .panel-btns a {
    color: $white;
  }
}
.panel-warning {
  .panel-heading {
    border-bottom: 0;
    background-color: $color_casablanca_approx;
    p {
      color: $white;
      opacity: 0.6;
    }
  }
  .panel-title {
    color: $white;
  }
}
.panel-danger {
  .panel-heading {
    border-bottom: 0;
    background-color: $color_chestnut_rose_approx;
    p {
      color: $white;
      opacity: 0.6;
    }
  }
  .panel-title {
    color: $white;
  }
  .panel-btns a {
    color: $white;
  }
}
.panel-info {
  .panel-heading {
    border-bottom: 0;
    background-color: $color_viking_approx;
    color: $white;
    p {
      color: $white;
      opacity: 0.6;
    }
  }
  .panel-title {
    color: $white;
  }
}
.panel-dark {
  .panel-heading {
    background-color: $color_ebony_clay_approx;
  }
  .panel-title {
    color: $white;
  }
  .panel-btns a {
    color: $white;
  }
}
.panel-title {
  font-size: 24px;
  color: $color_woodsmoke_approx;
  font-weight: 300;
}
.panel-title-alt {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  + p {
    margin-top: 5px !important;
  }
}
.panel-body {
  height: 100%;
  overflow: scroll;
  position: relative;
}
.panel-body-nopadding {
  padding: 0;
}
.panel-alt {
  .panel-heading {
    padding: 20px;
    p {
      margin-top: 5px;
      line-height: 16px;
    }
  }
  .panel-title {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .panel-footer {
    padding: 15px;
  }
  .panel-body {
    padding: 15px;
  }
  .panel-btns a {
    margin-top: -3px;
  }
}

.panel-group {
  .panel-title {
    font-size: 14px;
    font-weight: bold;
    a {
      padding: 14px 15px;
      display: block;
      &:hover {
        text-decoration: none;
      }
      &:focus {
        text-decoration: none;
      }
      &:active {
        text-decoration: none;
      }
      &.collapsed {
        background-color: $color_black_haze_approx;
        color: $color_storm_dust_approx;
        border-bottom: 1px solid $color_alto_approx;
        &:hover {
          background-color: $color_concrete_approx;
        }
      }
    }
  }
  .panel {
    &:last-child {
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0 0 3px 3px;
      .panel-title a {
        border-bottom: 0;
      }
    }
    &:first-child {
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 3px 3px 0 0;
    }
    + .panel {
      margin-top: 0;
      border-top: 0;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0;
    }
  }
  .panel-heading h4 {
    margin: 0;
  }
}
.panel-group-dark {
  .panel {
    border-bottom: 0;
  }
  .panel-title a.collapsed {
    background-color: $color_ebony_clay_approx;
    color: $color_celeste_approx;
    border-bottom: 1px solid $color_tuna_approx;
    &:hover {
      background-color: $color_mirage_approx;
      color: $white;
    }
  }
}

.panel-default > .panel-heading {
  border-color: $color_mercury_approx;
  background-color: $white;
}
.panel-btns a {
  margin-left: 8px;
  float: right;
  color: $black;
  display: inline-block;
  font-weight: 500;
  opacity: 0.2;
  font-size: 16px;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.2s ease-out 0s;
  &.minimize {
    font-size: 18px;
    &:active {
      opacity: 0.2;
      text-decoration: none;
    }
    &:focus {
      opacity: 0.2;
      text-decoration: none;
    }
  }
  &:hover {
    text-decoration: none;
    opacity: 0.5;
    cursor: pointer;
  }
}

.panel-main {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
  background: none;
  border-bottom-color: $color_gallery_approx;
  .panel-heading {
    background: none;
    border-bottom-color: $color_gallery_approx;
    padding-left: 0;
    padding-right: 0;
  }
  .panel-body {
    padding-left: 0;
    padding-right: 0;
  }
}
.panel-search {
  position: relative;
  &:before {
    position: absolute;
    top: 9px;
    left: 10px;
    content: '\f002';
    font-family: $font_2;
    color: $color_mountain_mist_approx;
  }
  .form-control {
    padding-left: 30px;
  }
  input[type="search"] {
    display: inline-block;
    vertical-align: middle;
  }
}

.te-panel-white {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
  border: 1px solid $color_celeste_approx;
  border-color: $color_celeste_approx;
  .panel-title {
    font-size: 17px;
    font-weight: 400;
    padding: 10px 0;
  }
  > {
    .panel-heading {
      color: $color_fuscous_gray_approx;
      background-color: $white;
      border-color: transparent;
      .badge {
        color: $white;
        background-color: $color_fuscous_gray_approx;
      }
      + .panel-collapse > .panel-body {
        border-top-color: $color_celeste_approx;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: $color_celeste_approx;
    }
  }
}
.panel-search-btn {
  .form-control {
    padding-left: 10px;
  }
  &:before {
    content: "";
  }
}


.panel-footer-tab {
  margin-top: 20px;
  padding: 20px 0 0;
}
