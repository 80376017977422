.absolute { position: absolute; }

.baseline { vertical-align: baseline; }

.bg-gray-lighter { background-color: $color_gallery_approx; }
.bg-gray-light { background-color: $color_mountain_mist_approx; }
.bg-gray { background-color: $color_storm_dust_approx; }

.block { display: block; }

.border-radius3 { border-radius: 3px; }
.border-radius4 { border-radius: 4px; }
.border-radius5 { border-radius: 5px; }

.border-right-0 { border-right: 0; }
.border-left-0 { border-left: 0; }

.dark { color: $color_mine_shaft_approx; }

.emptyblock {
  height: 150px;
  background: $color_celeste_approx;
}

.fnormal { font-weight: normal;

hr.light {
  border-color: $color_celeste_approx;
  margin: 0 0 15px 0;
}}

.inline-block { display: inline-block; }
.italic { font-style: italic; }

.minheight500 { min-height: 500px; }

.mb0 { margin-bottom: 0; }
.mb5 { margin-bottom: 5px; }
.mb8 { margin-bottom: 8px; }
.mb10 { margin-bottom: 10px; }
.mb15 { margin-bottom: 15px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }
.mr5 { margin-right: 5px; }
.mr10 { margin-right: 10px; }
.mr20 { margin-right: 20px; }
.mr30 { margin-right: 30px; }
.mt0 { margin-top: 0; }
.mt5 { margin-top: 5px; }
.mt8 { margin-top: 8px; }
.mt10 { margin-top: 10px; }
.mt15 { margin-top: 15px; }
.mt20 { margin-top: 20px; }
.mt25 { margin-top: 25px; }
.mt30 { margin-top: 30px; }
.ml1 { margin-left: 1px; }
.ml5 { margin-left: 5px; }
.ml10 { margin-left: 10px; }
.ml20 { margin-left: 20px; }
.ml30 { margin-left: 30px; }

.nomargin { margin: 0; }
.nopadding { padding: 0; }
.noshadow {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
}

.padding-top-0 { padding-top: 0; }
.padding-top-20 { padding-top: 20px; }
.padding-bottom-0 { padding-bottom: 0; }
.padding-right-5 { padding-right: 5px; }
.padding5 { padding: 5px; }
.padding10 { padding: 10px; }
.padding20 { padding: 20px; }

.relative { position: relative; }
.req { color: $red; }

.serif { font-family: $font_0, $font_4; }

.visibility-collapse { visibility: collapse; }
.valign-bottom { vertical-align: bottom; }
.valign-middle { vertical-align: middle; }

.width100p { width: 100%; }
.width20 { width: 20px; }
.width30 { width: 30px; }
.width50p { width: 50%; }
.width70p { width: 70%; }

.word-break { word-break: break-word; }

.table-layout-fixed { table-layout: fixed; }

.text-align-center { text-align: center; }
.text-black { color: $color_mine_shaft_approx;
.text-success { color: $color_mountain_meadow_approx; }}
.tooltip.tooltipShowAbove {
  .tooltip-inner {
    max-width: 1000px;
  }
}

.lh40 {
  line-height: 40px;
}
