.mainpanel-header {
  background-color: #fff;
  // border-bottom: 1px solid $color_alto_approx;
  // border-top: 1px solid $color_alto_approx;
  .panel-title {
    font-size: 18px;
    font-weight: normal;
    color: #000;
    padding: 11px 0 14px;
    display: flex;
    align-items: center;
  }
  .btn-primary {
    background-color: $color_mantis_approx;
    border-color: $color_sushi_approx;
    &:hover {
      background-color: $color_sushi_approx;
      border-color: $color_apple_approx;
    }
  }
  .invisible {
    display: none;
  }
  .typeahead-wrapper {
    form {
      margin-top: 10px;
    }
    input.form-control {
      padding: 5px 42px 5px 5px;
    }
  }
}

.mainpanel-body {
  margin: auto;
  height: 100%;
  padding: 0 16px;
}

.logo-container {
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  .close {
    position: absolute;
    right: 5px;
  }
}

.no-logo {
  height: 52px;
  background: #dedede;
  width: 52px;
  padding: 4px;
  text-align: center;
  font-size: 10px;
  line-height: 42px;
  border: 2px solid #aaaaaa;
  border-style: dashed;
  font-weight: 300;
}

.mainpanel {
  .panel-main {
  }
  .panel-main-withmenu {
    margin-left: $left-menu-size + $left-menu-gap;
  }
}
