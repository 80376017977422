
.media {
  padding-bottom: 15px;
}
.media-heading {
  color: $color_mine_shaft_approx;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
