.pagination {
  > li {
    &:first-child {
      > a {
        //Instead of the line below you could use @include border-bottom-left-radius($radius)
        border-bottom-left-radius: 3px;
        //Instead of the line below you could use @include border-top-left-radius($radius)
        border-top-left-radius: 3px;
      }
      > span {
        //Instead of the line below you could use @include border-bottom-left-radius($radius)
        border-bottom-left-radius: 3px;
        //Instead of the line below you could use @include border-top-left-radius($radius)
        border-top-left-radius: 3px;
      }
    }
    &:last-child {
      > a {
        //Instead of the line below you could use @include border-bottom-right-radius($radius)
        border-bottom-right-radius: 3px;
        //Instead of the line below you could use @include border-top-right-radius($radius)
        border-top-right-radius: 3px;
      }
      > span {
        //Instead of the line below you could use @include border-bottom-right-radius($radius)
        border-bottom-right-radius: 3px;
        //Instead of the line below you could use @include border-top-right-radius($radius)
        border-top-right-radius: 3px;
      }
    }
    > a {
      color: $color_nevada_approx;
      &:hover {
        background-color: $color_mystic_approx;
      }
      &:focus {
        background-color: $color_mystic_approx;
      }
    }
    > span {
      color: $color_nevada_approx;
      &:hover {
        background-color: $color_mystic_approx;
      }
      &:focus {
        background-color: $color_mystic_approx;
      }
    }
  }
  > .active {
    > a {
      background-color: $color_nevada_approx;
      border-color: $color_nevada_approx;
      &:hover {
        background-color: $color_nevada_approx;
        border-color: $color_nevada_approx;
      }
      &:focus {
        background-color: $color_nevada_approx;
        border-color: $color_nevada_approx;
      }
    }
    > span {
      background-color: $color_nevada_approx;
      border-color: $color_nevada_approx;
      &:hover {
        background-color: $color_nevada_approx;
        border-color: $color_nevada_approx;
      }
      &:focus {
        background-color: $color_nevada_approx;
        border-color: $color_nevada_approx;
      }
    }
  }
}

 ul.pagination {
   margin: 0;
 }

.pagination-split li {
  margin-left: 5px;
  display: inline-block;
  float: left;
  &:first-child {
    margin-left: 0;
  }
  a {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px;
  }
}
.pager li {
  > a {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px;
    color: $color_nevada_approx;
  }
  > span {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px;
    color: $color_nevada_approx;
  }
}
