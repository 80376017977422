.label-default {
  background-color: $color_mountain_mist_approx;
}
.label-default[href] {
  &:hover {
    background-color: $gray;
  }
  &:focus {
    background-color: $gray;
  }
}
.label-primary {
  background-color: $color_boston_blue_approx;
}
.label-primary[href] {
  &:hover {
    background-color: $color_astral_approx;
  }
  &:focus {
    background-color: $color_astral_approx;
  }
}
.label-success[href] {
  &:hover {
    background-color: $color_apple_approx;
  }
  &:focus {
    background-color: $color_apple_approx;
  }
}
.label-info {
  background-color: $color_viking_approx;
}
.label-info[href] {
  &:hover {
    background-color: $color_scooter_approx;
  }
  &:focus {
    background-color: $color_scooter_approx;
  }
}
.label-warning {
  background-color: $color_casablanca_approx;
}
.label-warning[href] {
  &:hover {
    background-color: $color_carrot_orange_approx;
  }
  &:focus {
    background-color: $color_carrot_orange_approx;
  }
}
.label-danger {
  background-color: $color_chestnut_rose_approx;
}
.label-danger[href] {
  &:hover {
    background-color: $color_persian_red_approx;
  }
  &:focus {
    background-color: $color_persian_red_approx;
  }
}

.label {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 2px;
  padding: 4px 8px;
  font-weight: normal;
  font-size: 11px;
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  display: inline-block;
}
.label-success {
  background-color: $color_mountain_meadow_approx;
  background-color: $color_mantis_approx;
}

.label-block {
  display: block;
  font-weight: 500;
  color: $color_mine_shaft_approx;
}