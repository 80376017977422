.dropdown-menu {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px;
  padding: 5px;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 3px 3px 0 $color_cod_gray_3_approx;
  min-width: 120px;
  li a {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.2s ease-out 0s;
  }
  > li > a {
    padding: 7px 10px;
    color: $color_storm_dust_approx;
    &:hover {
      background-color: $color_boston_blue_approx;
      color: $white;
    }
    > i {
      width: 16px;
      margin-right: 5px;
    }
  }
}

.dropdown-menu-sm > li {
  font-size: 12px;
  a {
    padding: 5px 10px;
  }
  &.divider {
    margin: 5px 0;
  }
}