body {
  background: $white;
  color: $color_nevada_approx;
  line-height: 21px;
  font-size: 13px;
  padding-top: 70px;
}

a {
  outline: none;
  &:hover {
    outline: none;
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}

label {
  font-weight: normal;
  margin-bottom: 5px !important;
  color: $color_trout_approx;
}
code {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 2px;
  background: none;
  padding: 0;
}
small {
  font-size: 11px;
  &.media-desc {
    line-height: 16px;
    font-size: 12px;
    display: block;
  }
}

blockquote {
  p {
    font-family: $font_0, $font_1;
    font-weight: 100;
    color: $color_mine_shaft_approx;
  }
  .glyphicon {
    @extend %extend_1;
  }
  .fa {
    @extend %extend_1;
  }
}
h4 {
  line-height: 24px;
}

.h4 {
  line-height: 24px;
}

.lead {
  color: $color_mine_shaft_approx;
}

tr.default {
  background: #f4fff6;
}
