.panel-wizard-content {
  margin-left: 270px;
  padding-left: 30px;
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 270px;
    width: 1px;
    border-left: 1px solid $color_alto_approx;
    content: '';
    z-index: -1;
  }
  .tab-content {
    padding: 0;
  }
  .form-group {
    margin-bottom: 20px;
  }
}
.panel-wizard-title {
  margin: 0;
  font-weight: 400;
  color: $color_mantis_approx;
  font-size: 22px;
  line-height: normal;
  .step {
    font-weight: 300;
    color: $color_mountain_mist_approx;
  }
}
.panel-wizard-footer {
  padding-top: 20px;
  border-top: 1px solid $color_gallery_approx;
}


.panel-wizard {
  clear: both;
  margin: 30px 0;
  position: relative;
  min-height: 170px;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}
.panel-wizard-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 240px;
}