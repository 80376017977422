.alert-warning-dark {
  border: 1px solid $color_golden_grass_approx;
  background-color: $color_golden_sand_approx;
  color: $color_himalaya_approx;
  opacity: 0.9;
  padding: 7px 10px;
  text-align: center;
}

.alert-wrapper {
  position: absolute;
  top: 125px;
  left: 50%;
  width: 400px;
  z-index: 10000;
  .alert {
    position: relative;
    width: 400px;
    margin-left: -150px;
    a {
      color: $black;
    }
    .close {
      line-height: 12px;
    }
  }
}
.alert-page-topcenter {
  margin: 0;
  .alert {
    background-color: $color_astra_approx;
    border-color: $color_rob_roy_approx;
    color: $black;
    padding: 5px 10px;
    text-align: center;
    font-size: 12px;
  }
}

.alert {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px;
  .close {
    font-size: 16px;
    margin-top: 3px;
  }
  .alert-link {
    font-weight: bold;
    font-weight: normal;
  }
  > p + p {
    margin-top: 15px;
    .btn {
      margin-right: 5px;
    }
  }
}


.alert-main {
  background-color: $color_viking_approx;
  color: $white;
  position: relative;
  margin-bottom: 15px;
  .alert-head {
    margin-top: 0;
  }
  a {
    color: $color_lemon_chiffon_approx;
  }
  p {
    margin: 0;
  }
  .alert-skip {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}



.alert-options {
  padding: 15px;
  border-bottom: 1px solid $color_alto_approx;
}