$modal-sizes: (
  xs: 374px,
  sm: 490px,
  md: 1205px,
  lg: 1366px
);
$modal-bg-color: #efefef;
.o-modal {
  .ui18 {
    .modal-dialog {
      opacity: 0;
      //transition-property: all !important;
      @include vendorPrefix(transition-property, all !important);
      @include vendorPrefix(transition-duration, 0.6s !important);
      //transition-duration: 0.7s !important;
      position: absolute;
      left: 50%;
      @include vendorPrefix(transform, translateY(-85%) !important);
      //transform: translateY(-85%) !important;
      top: 50%;
    }
    &.in .modal-dialog {
      opacity: 1 !important;
      transform: translateY(-70%) !important;
    }
    //==>sizes
    @each $key, $value in $modal-sizes {
      .modal-#{$key} {
        width: #{$value};
        margin-left: -#{($value/2)};
      }
    }
    //============== HEADER
    .modal-header {
      background: $modal-bg-color;
      height: 66px;
      text-align: center;
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #e0e0e0;
      button.close {
        border: none;
        color: #333;
        opacity: 1;
        height: 18px;
        width: 18px;
        padding: 0;
        margin-top: 8px;
        font-size: 18px;
        font-weight: 400;
        outline: none !important;
      }
      .modal-title {
        font-family: $uiFontFamily;
        font-size: 24px;
        text-align: center;
        color: #333333;
      }
    }
    //============== BODY
    .modal-body {
      background: $modal-bg-color;
      min-height: 150px;
    }
    //============== FOOTER
    .modal-footer {
      background: $modal-bg-color;
      border-top: 1px solid #e0e0e0;
    }
  }
}
