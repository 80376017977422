
.progress {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px;
  height: 15px;
  margin-bottom: 15px;
}
.progress-bar {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
}
.progress-bar-success {
  background-color: $color_mountain_meadow_approx;
}
.progress-sm {
  height: 10px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 2px;
}