.head-right {
  margin-top: 5px;
  .user-menu {
    margin: 0 15px 0 0;
    float: left;
    .btn {
      border: 0;
      font-weight: 400;
      font-size: 13px;
      margin: 0;
      padding: 0;
      background: none;
      color: $color_celeste_approx;
      &:hover {
        color: $white;
        .profile-photo {
          border-color: $color_suva_gray_approx;
        }
      }
      &:focus {
        color: $white;
      }
    }
    .caret {
      margin-left: 5px;
      margin-top: -2px;
      opacity: 0.5;
    }
    .dropdown-menu {
      margin-top: 16px;
      min-width: 170px;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0 0 3px 3px;
      font-size: 12px;
      border: 1px solid $color_alto_approx;
      border-top: 0;
      a:hover {
        background-color: $color_tundora_approx;
        color: $white;
      }
    }
    &.open .btn .profile-photo {
      border-color: $color_suva_gray_approx;
    }
  }
  .noti-menu {
    margin: 5px 15px 0 0;
    float: left;
  }
  .appshow-menu {
    .btn {
      display: inline-block;
      width: 20px;
      height: 25px;
      opacity: 0.5;
      color: $color_storm_dust_approx;
      background: none;
      border: 0;
      padding: 0;
      font-size: 21px;
      &:hover {
        opacity: 1;
        //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        box-shadow: none;
      }
      &:focus {
        opacity: 1;
        //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        box-shadow: none;
      }
      &:active {
        opacity: 1;
        //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        box-shadow: none;
      }
    }
    .dropdown-menu {
      margin-top: 23px;
      min-width: 250px;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0 0 3px 3px;
      border: 1px solid $color_alto_approx;
      border-top: 0;
    }
    &.open .btn {
      opacity: 1;
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: none;
    }
  }
  .app-list {
    padding: 5px 10px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    .col-xs-4 {
      padding: 0 5px;
    }
    a {
      display: block;
      padding: 10px;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 3px;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: all 0.2s ease-out 0s;
      font-size: 12px;
      color: $color_storm_dust_approx;
      line-height: normal;
      &:hover {
        background-color: $color_mystic_approx;
        text-decoration: none;
        color: $color_mine_shaft_approx;
      }
    }
    img {
      display: block;
      margin-bottom: 5px;
    }
  }
}

.head-notif {
  background: $color_sunset_orange_approx;
  color: $white;
  padding: 5px 10px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 50px;
  line-height: normal;
  &:hover {
    @extend %extend_19;
  }
  &:focus {
    @extend %extend_19;
  }
  &:active {
    @extend %extend_19;
  }
}

.panel-content {
  margin: 15px 15px 30px 15px;
}
