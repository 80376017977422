.tag-value-tree {
  li {
    .dropdown {
      visibility: hidden;
    }

    &:hover {
      cursor: pointer;
    }


    &:hover .dropdown {
      visibility: visible;
    }
  }



}