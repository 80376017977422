.footerpanel {
  padding: 0 20px;
  font-size: 12px;
  background: $color_mystic_approx;
  border-top: 1px solid $color_alto_approx;
  &:after {
    clear: both;
    display: block;
    content: '';
  }
  .nav {
    padding: 0;
    margin: 0;
    vertical-align: middle;
    > li {
      display: inline-block;
      > a {
        color: $color_storm_dust_approx;
        padding: 10px 15px;
        //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
        transition: all 0.2s ease-out 0s;
        &:hover {
          color: $color_mine_shaft_approx;
          background: none;
        }
      }
    }
  }
  .pull-right span {
    display: block;
    margin-top: 10px;
  }
}
.footerinner {
  margin: auto;
  max-width: 1280px;
}
.footerfixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  .nav li {
    a {
      outline: none;
      &:focus {
        background: none;
      }
      &:active {
        background: none;
      }
    }
    &:first-child a {
      padding-left: 0;
    }
  }
}