.btn {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px;
  line-height: 21px;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.2s ease-out 0s;
  padding: 8px 15px;
  &:focus {
    outline: none;
  }
}
.btn-sm {
  padding: 5px 10px;
}
.btn-xs {
  padding: 4px 10px;
}
.btn-lg {
  padding: 12px 20px;
}
.btn-default {
  background: $color_mystic_approx;
  color: $color_nevada_approx;
  color: $color_mine_shaft_approx;
  background-color: $white;
  border-color: $color_celeste_approx;
  &:hover {
    @extend %extend_2;
    @extend %extend_23;
  }
  &:focus {
    @extend %extend_2;
    @extend %extend_23;
  }
  &:active {
    @extend %extend_2;
    @extend %extend_23;
    background-image: none;
  }
  &.active {
    @extend %extend_2;
    @extend %extend_23;
    background-image: none;
  }
  &.disabled {
    background-color: $white;
    border-color: $color_celeste_approx;
    &:hover {
      background-color: $white;
      border-color: $color_celeste_approx;
    }
    &:focus {
      background-color: $white;
      border-color: $color_celeste_approx;
    }
    &:active {
      background-color: $white;
      border-color: $color_celeste_approx;
    }
    &.active {
      background-color: $white;
      border-color: $color_celeste_approx;
    }
  }
  .badge {
    color: $white;
    background-color: $color_mine_shaft_approx;
  }
}

.btn-primary {
  color: $white;
  background-color: $color_boston_blue_approx;
  border-color: $color_steel_blue_approx;
  &:active {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
    @extend %extend_24;
    background-image: none;
  }
  &.active {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
    @extend %extend_24;
    background-image: none;
  }
  &:hover {
    @extend %extend_24;
  }
  &:focus {
    @extend %extend_24;
  }
  &.disabled {
    background-color: $color_boston_blue_approx;
    border-color: $color_steel_blue_approx;
    &:hover {
      background-color: $color_boston_blue_approx;
      border-color: $color_steel_blue_approx;
    }
    &:focus {
      background-color: $color_boston_blue_approx;
      border-color: $color_steel_blue_approx;
    }
    &:active {
      background-color: $color_boston_blue_approx;
      border-color: $color_steel_blue_approx;
    }
    &.active {
      background-color: $color_boston_blue_approx;
      border-color: $color_steel_blue_approx;
    }
  }
  .badge {
    color: $color_boston_blue_approx;
    background-color: $white;
  }
}
.btn-success {
  color: $white;
  background-color: $color_mantis_approx;
  border-color: $color_sushi_approx;
  &:hover {
    background-color: $color_mantis_approx;
    border-color: $color_sushi_approx;
    @extend %extend_25;
  }
  &:active {
    background-color: $color_mantis_approx;
    border-color: $color_sushi_approx;
    @extend %extend_25;
    background-image: none;
  }
  &.active {
    background-color: $color_mantis_approx;
    border-color: $color_sushi_approx;
    @extend %extend_25;
    background-image: none;
  }
  &:focus {
    @extend %extend_25;
  }
  &.disabled {
    background-color: $color_mantis_approx;
    border-color: $color_sushi_approx;
    &:hover {
      background-color: $color_mantis_approx;
      border-color: $color_sushi_approx;
    }
    &:focus {
      background-color: $color_mantis_approx;
      border-color: $color_sushi_approx;
    }
    &:active {
      background-color: $color_mantis_approx;
      border-color: $color_sushi_approx;
    }
    &.active {
      background-color: $color_mantis_approx;
      border-color: $color_sushi_approx;
    }
  }
  .badge {
    color: $color_mantis_approx;
    background-color: $white;
  }
}
.btn-white {
  background: $white;
  border: 1px solid $color_celeste_approx;
  color: $color_storm_dust_approx;
  &:hover {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
    background: $color_black_haze_approx;
  }
  &:focus {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
    background: $color_black_haze_approx;
  }
  &:active {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
    background: $color_black_haze_approx;
  }
  &.active {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
    background: $color_black_haze_approx;
  }
}
.btn-default-alt {
  border: 2px solid $color_mountain_mist_approx;
  color: $color_mountain_mist_approx;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  &:hover {
    border-color: $color_storm_dust_approx;
    color: $color_storm_dust_approx;
  }
  &:active {
    border-color: $color_storm_dust_approx;
    color: $color_storm_dust_approx;
  }
  &:focus {
    border-color: $color_storm_dust_approx;
    color: $color_storm_dust_approx;
  }
}
.btn-primary-alt {
  border: 2px solid $color_boston_blue_approx;
  color: $color_boston_blue_approx;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  &:hover {
    border-color: $color_steel_blue_approx;
    color: $color_steel_blue_approx;
  }
  &:active {
    border-color: $color_steel_blue_approx;
    color: $color_steel_blue_approx;
  }
  &:focus {
    border-color: $color_steel_blue_approx;
    color: $color_steel_blue_approx;
  }
}
.btn-success-alt {
  border: 2px solid $color_mountain_meadow_approx;
  color: $color_mountain_meadow_approx;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  &:hover {
    border-color: $color_niagara_approx;
    color: $color_niagara_approx;
  }
  &:active {
    border-color: $color_niagara_approx;
    color: $color_niagara_approx;
  }
  &:focus {
    border-color: $color_niagara_approx;
    color: $color_niagara_approx;
  }
}
.btn-warning {
  color: $white;
  background-color: $color_casablanca_approx;
  border-color: $color_fuel_yellow_approx;
  &:hover {
    @extend %extend_3;
    @extend %extend_27;
  }
  &:focus {
    @extend %extend_3;
    @extend %extend_27;
  }
  &:active {
    @extend %extend_3;
    background-image: none;
    @extend %extend_27;
    background-image: none;
  }
  &.active {
    @extend %extend_3;
    background-image: none;
    @extend %extend_27;
    background-image: none;
  }
  &.disabled {
    background-color: $color_casablanca_approx;
    border-color: $color_fuel_yellow_approx;
    &:hover {
      background-color: $color_casablanca_approx;
      border-color: $color_fuel_yellow_approx;
    }
    &:focus {
      background-color: $color_casablanca_approx;
      border-color: $color_fuel_yellow_approx;
    }
    &:active {
      background-color: $color_casablanca_approx;
      border-color: $color_fuel_yellow_approx;
    }
    &.active {
      background-color: $color_casablanca_approx;
      border-color: $color_fuel_yellow_approx;
    }
  }
  .badge {
    color: $color_casablanca_approx;
    background-color: $white;
  }
}
.btn-warning[disabled] {
  background-color: $color_casablanca_approx;
  border-color: $color_fuel_yellow_approx;
  &:hover {
    background-color: $color_casablanca_approx;
    border-color: $color_fuel_yellow_approx;
  }
  &:focus {
    background-color: $color_casablanca_approx;
    border-color: $color_fuel_yellow_approx;
  }
  &:active {
    background-color: $color_casablanca_approx;
    border-color: $color_fuel_yellow_approx;
  }
  &.active {
    background-color: $color_casablanca_approx;
    border-color: $color_fuel_yellow_approx;
  }
}


fieldset[disabled] {
  .btn-warning {
    background-color: $color_casablanca_approx;
    border-color: $color_fuel_yellow_approx;
    &:hover {
      background-color: $color_casablanca_approx;
      border-color: $color_fuel_yellow_approx;
    }
    &:focus {
      background-color: $color_casablanca_approx;
      border-color: $color_fuel_yellow_approx;
    }
    &:active {
      background-color: $color_casablanca_approx;
      border-color: $color_fuel_yellow_approx;
    }
    &.active {
      background-color: $color_casablanca_approx;
      border-color: $color_fuel_yellow_approx;
    }
  }
  .btn-danger {
    background-color: $color_chestnut_rose_approx;
    border-color: $color_valencia_approx;
    &:hover {
      background-color: $color_chestnut_rose_approx;
      border-color: $color_valencia_approx;
    }
    &:focus {
      background-color: $color_chestnut_rose_approx;
      border-color: $color_valencia_approx;
    }
    &:active {
      background-color: $color_chestnut_rose_approx;
      border-color: $color_valencia_approx;
    }
    &.active {
      background-color: $color_chestnut_rose_approx;
      border-color: $color_valencia_approx;
    }
  }
  .btn-info {
    background-color: $color_viking_approx;
    border-color: $color_picton_blue_approx;
    &:hover {
      background-color: $color_viking_approx;
      border-color: $color_picton_blue_approx;
    }
    &:focus {
      background-color: $color_viking_approx;
      border-color: $color_picton_blue_approx;
    }
    &:active {
      background-color: $color_viking_approx;
      border-color: $color_picton_blue_approx;
    }
    &.active {
      background-color: $color_viking_approx;
      border-color: $color_picton_blue_approx;
    }
  }
  .btn-link {
    background-color: transparent;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
    &:hover {
      color: $color_olivine_approx;
      text-decoration: none;
    }
    &:focus {
      color: $color_olivine_approx;
      text-decoration: none;
    }
  }
  .btn-default {
    background-color: $white;
    border-color: $color_celeste_approx;
    &:hover {
      background-color: $white;
      border-color: $color_celeste_approx;
    }
    &:focus {
      background-color: $white;
      border-color: $color_celeste_approx;
    }
    &:active {
      background-color: $white;
      border-color: $color_celeste_approx;
    }
    &.active {
      background-color: $white;
      border-color: $color_celeste_approx;
    }
  }
  .btn-primary {
    background-color: $color_boston_blue_approx;
    border-color: $color_steel_blue_approx;
    &:hover {
      background-color: $color_boston_blue_approx;
      border-color: $color_steel_blue_approx;
    }
    &:focus {
      background-color: $color_boston_blue_approx;
      border-color: $color_steel_blue_approx;
    }
    &:active {
      background-color: $color_boston_blue_approx;
      border-color: $color_steel_blue_approx;
    }
    &.active {
      background-color: $color_boston_blue_approx;
      border-color: $color_steel_blue_approx;
    }
  }
  .btn-success {
    background-color: $color_mantis_approx;
    border-color: $color_sushi_approx;
    &:hover {
      background-color: $color_mantis_approx;
      border-color: $color_sushi_approx;
    }
    &:focus {
      background-color: $color_mantis_approx;
      border-color: $color_sushi_approx;
    }
    &:active {
      background-color: $color_mantis_approx;
      border-color: $color_sushi_approx;
    }
    &.active {
      background-color: $color_mantis_approx;
      border-color: $color_sushi_approx;
    }
  }
}
.btn-warning-alt {
  border: 2px solid $color_casablanca_approx;
  color: $color_casablanca_approx;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  &:hover {
    border-color: $color_fuel_yellow_approx;
    color: $color_fuel_yellow_approx;
  }
  &:active {
    border-color: $color_fuel_yellow_approx;
    color: $color_fuel_yellow_approx;
  }
  &:focus {
    border-color: $color_fuel_yellow_approx;
    color: $color_fuel_yellow_approx;
  }
}
.btn-danger {
  color: $white;
  background-color: $color_chestnut_rose_approx;
  border-color: $color_valencia_approx;
  &:hover {
    @extend %extend_4;
    @extend %extend_28;
  }
  &:focus {
    @extend %extend_4;
    @extend %extend_28;
  }
  &:active {
    @extend %extend_4;
    background-image: none;
    @extend %extend_28;
    background-image: none;
  }
  &.active {
    @extend %extend_4;
    background-image: none;
    @extend %extend_28;
    background-image: none;
  }
  &.disabled {
    background-color: $color_chestnut_rose_approx;
    border-color: $color_valencia_approx;
    &:hover {
      background-color: $color_chestnut_rose_approx;
      border-color: $color_valencia_approx;
    }
    &:focus {
      background-color: $color_chestnut_rose_approx;
      border-color: $color_valencia_approx;
    }
    &:active {
      background-color: $color_chestnut_rose_approx;
      border-color: $color_valencia_approx;
    }
    &.active {
      background-color: $color_chestnut_rose_approx;
      border-color: $color_valencia_approx;
    }
  }
  .badge {
    color: $color_chestnut_rose_approx;
    background-color: $white;
  }
}
.btn-danger[disabled] {
  background-color: $color_chestnut_rose_approx;
  border-color: $color_valencia_approx;
  &:hover {
    background-color: $color_chestnut_rose_approx;
    border-color: $color_valencia_approx;
  }
  &:focus {
    background-color: $color_chestnut_rose_approx;
    border-color: $color_valencia_approx;
  }
  &:active {
    background-color: $color_chestnut_rose_approx;
    border-color: $color_valencia_approx;
  }
  &.active {
    background-color: $color_chestnut_rose_approx;
    border-color: $color_valencia_approx;
  }
}
.btn-danger-alt {
  border: 2px solid $color_chestnut_rose_approx;
  color: $color_chestnut_rose_approx;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  &:hover {
    border-color: $color_valencia_approx;
    color: $color_valencia_approx;
  }
  &:active {
    border-color: $color_valencia_approx;
    color: $color_valencia_approx;
  }
  &:focus {
    border-color: $color_valencia_approx;
    color: $color_valencia_approx;
  }
}
.btn-info-alt {
  border: 2px solid $color_viking_approx;
  color: $color_viking_approx;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  &:hover {
    border-color: $color_picton_blue_approx;
    color: $color_picton_blue_approx;
  }
  &:active {
    border-color: $color_picton_blue_approx;
    color: $color_picton_blue_approx;
  }
  &:focus {
    border-color: $color_picton_blue_approx;
    color: $color_picton_blue_approx;
  }
}
.btn-info {
  color: $white;
  background-color: $color_viking_approx;
  border-color: $color_picton_blue_approx;
  &:hover {
    @extend %extend_5;
    @extend %extend_26;
  }
  &:focus {
    @extend %extend_5;
    @extend %extend_26;
  }
  &:active {
    @extend %extend_5;
    background-image: none;
    @extend %extend_26;
    background-image: none;
  }
  &.active {
    @extend %extend_5;
    background-image: none;
    @extend %extend_26;
    background-image: none;
  }
  &.disabled {
    background-color: $color_viking_approx;
    border-color: $color_picton_blue_approx;
    &:hover {
      background-color: $color_viking_approx;
      border-color: $color_picton_blue_approx;
    }
    &:focus {
      background-color: $color_viking_approx;
      border-color: $color_picton_blue_approx;
    }
    &:active {
      background-color: $color_viking_approx;
      border-color: $color_picton_blue_approx;
    }
    &.active {
      background-color: $color_viking_approx;
      border-color: $color_picton_blue_approx;
    }
  }
  .badge {
    color: $color_viking_approx;
    background-color: $white;
  }
}
.btn-info[disabled] {
  background-color: $color_viking_approx;
  border-color: $color_picton_blue_approx;
  &:hover {
    background-color: $color_viking_approx;
    border-color: $color_picton_blue_approx;
  }
  &:focus {
    background-color: $color_viking_approx;
    border-color: $color_picton_blue_approx;
  }
  &:active {
    background-color: $color_viking_approx;
    border-color: $color_picton_blue_approx;
  }
  &.active {
    background-color: $color_viking_approx;
    border-color: $color_picton_blue_approx;
  }
}
.btn-brown {
  background-color: $color_irish_coffee_approx;
  border-color: $color_metallic_bronze_approx;
  color: $white;
  &:hover {
    @extend %extend_6;
  }
  &:focus {
    @extend %extend_6;
  }
}
.btn-brown-alt {
  border: 2px solid $color_irish_coffee_approx;
  color: $color_irish_coffee_approx;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  &:hover {
    border-color: $color_metallic_bronze_approx;
    color: $color_metallic_bronze_approx;
  }
  &:active {
    border-color: $color_metallic_bronze_approx;
    color: $color_metallic_bronze_approx;
  }
  &:focus {
    border-color: $color_metallic_bronze_approx;
    color: $color_metallic_bronze_approx;
  }
}
.btn-black {
  background: $color_mine_shaft_approx;
  color: $white;
  border-color: $color_log_cabin_approx;
  &:hover {
    @extend %extend_7;
  }
  &:active {
    @extend %extend_7;
  }
  &:focus {
    @extend %extend_7;
  }
}
.btn-black-alt {
  border: 2px solid $color_mine_shaft_approx;
  color: $color_mine_shaft_approx;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  &:hover {
    border: 2px solid $color_log_cabin_approx;
    color: $color_log_cabin_approx;
  }
  &:active {
    border: 2px solid $color_log_cabin_approx;
    color: $color_log_cabin_approx;
  }
  &:focus {
    border: 2px solid $color_log_cabin_approx;
    color: $color_log_cabin_approx;
  }
}
.btn-pink {
  background: $color_lavender_magenta_approx;
  border-color: $color_orchid_approx;
  color: $white;
  &:hover {
    @extend %extend_8;
  }
  &:active {
    @extend %extend_8;
  }
  &:focus {
    @extend %extend_8;
  }
}
.btn-pink-alt {
  border: 2px solid $color_lavender_magenta_approx;
  color: $color_lavender_magenta_approx;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  &:hover {
    border-color: $color_orchid_approx;
    color: $color_orchid_approx;
  }
  &:active {
    border-color: $color_orchid_approx;
    color: $color_orchid_approx;
  }
  &:focus {
    border-color: $color_orchid_approx;
    color: $color_orchid_approx;
  }
}
.btn-darkblue {
  color: $white;
  background: $color_astronaut_approx;
  border-color: $color_astronaut_approx;
  &:hover {
    @extend %extend_9;
  }
  &:active {
    @extend %extend_9;
  }
  &:focus {
    @extend %extend_9;
  }
}
.btn-darkblue-alt {
  border: 2px solid $color_astronaut_approx;
  color: $color_astronaut_approx;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  &:hover {
    border-color: $color_astronaut_approx;
    color: $color_astronaut_approx;
  }
  &:active {
    border-color: $color_astronaut_approx;
    color: $color_astronaut_approx;
  }
  &:focus {
    border-color: $color_astronaut_approx;
    color: $color_astronaut_approx;
  }
}
.btn-orange {
  color: $white;
  background: $color_gold_drop_approx;
  border-color: $color_mango_tango_approx;
  &:hover {
    @extend %extend_10;
  }
  &:active {
    @extend %extend_10;
  }
  &:focus {
    @extend %extend_10;
  }
}
.btn-orange-alt {
  border: 2px solid $color_gold_drop_approx;
  color: $color_gold_drop_approx;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  &:hover {
    border-color: $color_mango_tango_approx;
    color: $color_mango_tango_approx;
  }
  &:active {
    border-color: $color_mango_tango_approx;
    color: $color_mango_tango_approx;
  }
  &:focus {
    border-color: $color_mango_tango_approx;
    color: $color_mango_tango_approx;
  }
}
.btn-maroon {
  color: $white;
  background: $color_red_berry_approx;
  border-color: $color_red_berry_approx;
  &:hover {
    @extend %extend_11;
  }
  &:active {
    @extend %extend_11;
  }
  &:focus {
    @extend %extend_11;
  }
}
.btn-maroon-alt {
  border: 2px solid $color_red_berry_approx;
  color: $color_red_berry_approx;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  &:hover {
    border-color: $color_red_berry_approx;
    color: $color_red_berry_approx;
  }
  &:active {
    border-color: $color_red_berry_approx;
    color: $color_red_berry_approx;
  }
  &:focus {
    border-color: $color_red_berry_approx;
    color: $color_red_berry_approx;
  }
}
.btn-lightblue {
  background: $color_azure_radiance_approx;
  color: $white;
  border-color: $color_azure_radiance_approx;
  &:hover {
    @extend %extend_12;
  }
  &:active {
    @extend %extend_12;
  }
  &:focus {
    @extend %extend_12;
  }
}
.btn-lightblue-alt {
  border: 2px solid $color_azure_radiance_approx;
  color: $color_azure_radiance_approx;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  &:hover {
    border-color: $color_azure_radiance_approx;
    color: $color_azure_radiance_approx;
  }
  &:active {
    border-color: $color_azure_radiance_approx;
    color: $color_azure_radiance_approx;
  }
  &:focus {
    border-color: $color_azure_radiance_approx;
    color: $color_azure_radiance_approx;
  }
}
.btn-link {
  color: $color_cape_cod_approx;
  font-weight: normal;
  cursor: pointer;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
  padding: 0;
  border: 0;
  background: none;
  background-color: transparent;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
  border-color: transparent;
  &:active {
    background-color: transparent;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
    border-color: transparent;
  }
  &:hover {
    border-color: transparent;
    @extend %extend_13;
  }
  &:focus {
    border-color: transparent;
    @extend %extend_13;
  }
}
.btn-link[disabled] {
  background-color: transparent;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
  &:hover {
    color: $color_olivine_approx;
    text-decoration: none;
  }
  &:focus {
    color: $color_olivine_approx;
    text-decoration: none;
  }
}
.btn-group {
  &.open .dropdown-toggle {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
  }
}

.btn-default[disabled] {
  background-color: $white;
  border-color: $color_celeste_approx;
  &:hover {
    background-color: $white;
    border-color: $color_celeste_approx;
  }
  &:focus {
    background-color: $white;
    border-color: $color_celeste_approx;
  }
  &:active {
    background-color: $white;
    border-color: $color_celeste_approx;
  }
  &.active {
    background-color: $white;
    border-color: $color_celeste_approx;
  }
}
.btn-primary[disabled] {
  background-color: $color_boston_blue_approx;
  border-color: $color_steel_blue_approx;
  &:hover {
    background-color: $color_boston_blue_approx;
    border-color: $color_steel_blue_approx;
  }
  &:focus {
    background-color: $color_boston_blue_approx;
    border-color: $color_steel_blue_approx;
  }
  &:active {
    background-color: $color_boston_blue_approx;
    border-color: $color_steel_blue_approx;
  }
  &.active {
    background-color: $color_boston_blue_approx;
    border-color: $color_steel_blue_approx;
  }
}
.btn-success[disabled] {
  background-color: $color_mantis_approx;
  border-color: $color_sushi_approx;
  &:hover {
    background-color: $color_mantis_approx;
    border-color: $color_sushi_approx;
  }
  &:focus {
    background-color: $color_mantis_approx;
    border-color: $color_sushi_approx;
  }
  &:active {
    background-color: $color_mantis_approx;
    border-color: $color_sushi_approx;
  }
  &.active {
    background-color: $color_mantis_approx;
    border-color: $color_sushi_approx;
  }
}
.btn-search {
  position: absolute;
  right: 1px;
  height: 38px;
  width: 40px;
  border: none;
  top: 1px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0 2px 2px 0;
  background: none;
  &:hover {
    background: $color_wild_sand_approx;
  }
  &:focus {
    outline: none;
  }
  &:before {
    position: absolute;
    top: 8px;
    left: 15px;
    content: '\f002';
    font-family: $font_2;
    color: $color_mountain_mist_approx;
  }
}

.btn-assign {
  padding-left: 30px;
  &:before {
    font-family: $font_3;
    content: '\e008';
    line-height: normal;
    position: absolute;
    left: 8px;
    top: 4px;
  }
}


.btn-browm:active {
  @extend %extend_6;
}

td div.btn-group {
  position: absolute!important;
}

