.form-group {
  position: relative;
}
.form-group::after {
  clear: both;
  display: block;
  content: '';
}
.form-inline {
  .form-group {
    margin-right: 20px;
  }
  .checkbox {
    margin-right: 20px;
  }
}
.form-control {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px;
  padding: 10px;
  height: auto;

  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
  font-size: 13px;
  &:focus {
    border-color: $color_mountain_mist_approx;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 3px 3px 0 $color_cod_gray_5_approx;
  }
}
.input-sm {
  font-size: 12px;
  padding: 7px 10px;
  height: auto;
  margin-top: 3px;
}
.input-xs {
  padding: 7px 10px;
  font-size: 12px;
}
.input-lg {
  font-size: 18px;
  padding: 12px 10px;
}

.form-bordered .form-group {
  margin: 0;
  padding: 15px 0;
  border-top: 1px solid $color_iron_approx;
  &:first-child {
    border-top: 0;
  }
}
.form-striped .form-group {
  margin: 0;
  padding: 15px 0;
  &:nth-child(even) {
    background: $color_alabaster_approx;
  }
}

.form-horizontal .control-label {
  font-weight: normal;
}


.has-success .form-control {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
  &:focus {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 5px 5px 0 $color_cod_gray_5_approx;
  }
}
.has-warning .form-control {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
  &:focus {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 5px 5px 0 $color_cod_gray_5_approx;
  }
}
.has-error .form-control {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
  border-color: $color_guardsman_red_approx;
  &:focus {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 5px 5px 0 $color_cod_gray_5_approx;
  }
}
.input-group-lg > {
  .form-control {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    height: 46px;
  }
  .input-group-addon {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    height: 46px;
  }
  .input-group-btn > .btn {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    height: 46px;
  }
}
.input-group-btn .btn {
  line-height: 22px;
}

.form-legend {
  border-bottom: 1px solid $color_mercury_approx;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-size: 12px;
  color: $color_mine_shaft_approx;
  margin-bottom: 20px;
}
.input-group-addon {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 2px;
  font-size: 13px;
  background-color: $color_black_haze_approx;
}

.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  height: inherit;
}

.form-filter md-select {
  margin-left: 15px;
}
