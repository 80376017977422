.tag-group-wrapper {
  position: relative;
  border: 1px solid $border-color2 1px solid $border-color2 none 1px solid $border-color2;

  .truncate {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .list-group {
    overflow-y: scroll;
    height: 100%;
    font-size: 14px;
    position: relative;
    margin-bottom: 0;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      top: 0;
      left: $group-width;
      width: 1px;
      background-color: $border-color2;

    }

    .list-group-header {
      list-style: none;
      padding: 10px 15px 10px 15px;
      background-color: lighten($gray-lighter, 3%);
      color: #636e7b;
      width: $group-width;

      .btn {
        line-height: 18px;
        font-size: 12px;
        font-family: $font_1;
        border: 1px solid $border-color2;
        background-color: #fff;
        box-shadow: 1px 1px 0 rgba(0,0,0,.05);

        > i { margin-right: 5px; }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      width: $group-width;
      left: $group-width;
      //display: none;
      //border-right: 1px solid $border-color2;
    }

    .list-group-item {
      border-color: $border-color2;
      border-left: 0;
      border-right: 0;
      position: static;
      letter-spacing: -0.5px;
      padding-left: 15px;
      color: #636e7b;
      width: $group-width;

      a {
        color: #636e7b;
      }

      .list-inner { position: relative; }



      &:first-child {
        border-top: 0;
        @include border-top-radius(0);
      }

      &:last-child {
        border-bottom: 0;
        @include border-bottom-radius(0);
      }

      .tag-option {
        position: absolute;
        top: -1px;
        right: 0;

        > a {
          display: inline-block;
          vertical-align: middle;
          color: darken($gray-lighter, 5%);
          font-size: 14px;

          &:hover,
          &:focus {
            color: $color_steel_blue_approx; //$gray;
          }

          &:last-child { margin-left: 3px; }


          &:hover {
            color: $color_steel_blue_approx;
          }
        }
      }

      &:hover,
      &:focus {
        background-color: $gray-lighter;

        .tag-option {
          > a {
            color: $gray-light;
            &:hover,
            &:focus {
              color: $color_steel_blue_approx; //$gray;
            }
          }
        }
      }

      &.active {
        background-color: darken($gray-lighter, 5%);
        color: #000000;

        .tag-option > a { color: $gray-light; }
      }

      ul {
        display: block;
        top: 0;
        bottom: 0;
        list-style-type: none;

        li {
          border-left: 0;
          border-right: 0;

          &:first-child {
            border-top: 0;
            @include border-top-radius(0);
          }

          &:last-child { @include border-bottom-radius(0); }
        }
      }
    }
    .pagination-item {
      padding: 0px;

      input{
        min-width: 27px;
        max-width: 30px;
      }
    }
  }
}