@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/monokai.css';
@font-face {
  font-family: "Source Sans Pro";
  src: url("~@trustarc/fonts/fonts/SourceSansPro-ExtraLight.eot");
  src: url("~@trustarc/fonts/fonts/SourceSansPro-ExtraLight.eot?") format("eot"), url("~@trustarc/fonts/fonts/SourceSansPro-ExtraLight.woff2") format("woff2"), url("~@trustarc/fonts/fonts/SourceSansPro-ExtraLight.woff") format("woff"), url("~@trustarc/fonts/fonts/SourceSansPro-ExtraLight.ttf") format("truetype"), url("~@trustarc/fonts/fonts/SourceSansPro-ExtraLight.svg#ExtraLight") format("svg");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("~@trustarc/fonts/fonts/SourceSansPro-ExtraLightItalic.eot");
  src: url("~@trustarc/fonts/fonts/SourceSansPro-ExtraLightItalic.eot?") format("eot"), url("~@trustarc/fonts/fonts/SourceSansPro-ExtraLightItalic.woff2") format("woff2"), url("~@trustarc/fonts/fonts/SourceSansPro-ExtraLightItalic.woff") format("woff"), url("~@trustarc/fonts/fonts/SourceSansPro-ExtraLightItalic.ttf") format("truetype"), url("~@trustarc/fonts/fonts/SourceSansPro-ExtraLightItalic.svg#ExtraLightItalic") format("svg");
  font-style: italic;
  font-weight: 200;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("~@trustarc/fonts/fonts/SourceSansPro-Light.eot");
  src: url("~@trustarc/fonts/fonts/SourceSansPro-Light.eot?") format("eot"), url("~@trustarc/fonts/fonts/SourceSansPro-Light.woff2") format("woff2"), url("~@trustarc/fonts/fonts/SourceSansPro-Light.woff") format("woff"), url("~@trustarc/fonts/fonts/SourceSansPro-Light.ttf") format("truetype"), url("~@trustarc/fonts/fonts/SourceSansPro-Light.svg#Light") format("svg");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("~@trustarc/fonts/fonts/SourceSansPro-LightItalic.eot");
  src: url("~@trustarc/fonts/fonts/SourceSansPro-LightItalic.eot?") format("eot"), url("~@trustarc/fonts/fonts/SourceSansPro-LightItalic.woff2") format("woff2"), url("~@trustarc/fonts/fonts/SourceSansPro-LightItalic.woff") format("woff"), url("~@trustarc/fonts/fonts/SourceSansPro-LightItalic.ttf") format("truetype"), url("~@trustarc/fonts/fonts/SourceSansPro-LightItalic.svg#LightItalic") format("svg");
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("~@trustarc/fonts/fonts/SourceSansPro-SemiBold.eot");
  src: url("~@trustarc/fonts/fonts/SourceSansPro-SemiBold.eot?") format("eot"), url("~@trustarc/fonts/fonts/SourceSansPro-SemiBold.woff2") format("woff2"), url("~@trustarc/fonts/fonts/SourceSansPro-SemiBold.woff") format("woff"), url("~@trustarc/fonts/fonts/SourceSansPro-SemiBold.ttf") format("truetype"), url("~@trustarc/fonts/fonts/SourceSansPro-SemiBold.svg#SemiBold") format("svg");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("~@trustarc/fonts/fonts/SourceSansPro-SemiBoldItalic.eot");
  src: url("~@trustarc/fonts/fonts/SourceSansPro-SemiBoldItalic.eot?") format("eot"), url("~@trustarc/fonts/fonts/SourceSansPro-SemiBoldItalic.woff2") format("woff2"), url("~@trustarc/fonts/fonts/SourceSansPro-SemiBoldItalic.woff") format("woff"), url("~@trustarc/fonts/fonts/SourceSansPro-SemiBoldItalic.ttf") format("truetype"), url("~@trustarc/fonts/fonts/SourceSansPro-SemiBoldItalic.svg#SemiBoldItalic") format("svg");
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("~@trustarc/fonts/fonts/SourceSansPro-Black.eot");
  src: url("~@trustarc/fonts/fonts/SourceSansPro-Black.eot?") format("eot"), url("~@trustarc/fonts/fonts/SourceSansPro-Black.woff2") format("woff2"), url("~@trustarc/fonts/fonts/SourceSansPro-Black.woff") format("woff"), url("~@trustarc/fonts/fonts/SourceSansPro-Black.ttf") format("truetype"), url("~@trustarc/fonts/fonts/SourceSansPro-Black.svg#Black") format("svg");
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("~@trustarc/fonts/fonts/SourceSansPro-BlackItalic.eot");
  src: url("~@trustarc/fonts/fonts/SourceSansPro-BlackItalic.eot?") format("eot"), url("~@trustarc/fonts/fonts/SourceSansPro-BlackItalic.woff2") format("woff2"), url("~@trustarc/fonts/fonts/SourceSansPro-BlackItalic.woff") format("woff"), url("~@trustarc/fonts/fonts/SourceSansPro-BlackItalic.ttf") format("truetype"), url("~@trustarc/fonts/fonts/SourceSansPro-BlackItalic.svg#BlackItalic") format("svg");
  font-style: italic;
  font-weight: 900;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("~@trustarc/fonts/fonts/SourceSansPro-Bold.eot");
  src: url("~@trustarc/fonts/fonts/SourceSansPro-Bold.eot?") format("eot"), url("~@trustarc/fonts/fonts/SourceSansPro-Bold.woff2") format("woff2"), url("~@trustarc/fonts/fonts/SourceSansPro-Bold.woff") format("woff"), url("~@trustarc/fonts/fonts/SourceSansPro-Bold.ttf") format("truetype"), url("~@trustarc/fonts/fonts/SourceSansPro-Bold.svg#Bold") format("svg");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("~@trustarc/fonts/fonts/SourceSansPro-BoldItalic.eot");
  src: url("~@trustarc/fonts/fonts/SourceSansPro-BoldItalic.eot?") format("eot"), url("~@trustarc/fonts/fonts/SourceSansPro-BoldItalic.woff2") format("woff2"), url("~@trustarc/fonts/fonts/SourceSansPro-BoldItalic.woff") format("woff"), url("~@trustarc/fonts/fonts/SourceSansPro-BoldItalic.ttf") format("truetype"), url("~@trustarc/fonts/fonts/SourceSansPro-BoldItalic.svg#BoldItalic") format("svg");
  font-style: italic;
  font-weight: bold;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("~@trustarc/fonts/fonts/SourceSansPro-Regular.eot");
  src: url("~@trustarc/fonts/fonts/SourceSansPro-Regular.eot?") format("eot"), url("~@trustarc/fonts/fonts/SourceSansPro-Regular.woff2") format("woff2"), url("~@trustarc/fonts/fonts/SourceSansPro-Regular.woff") format("woff"), url("~@trustarc/fonts/fonts/SourceSansPro-Regular.ttf") format("truetype"), url("~@trustarc/fonts/fonts/SourceSansPro-Regular.svg#Regular") format("svg");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("~@trustarc/fonts/fonts/SourceSansPro-Italic.eot");
  src: url("~@trustarc/fonts/fonts/SourceSansPro-Italic.eot?") format("eot"), url("~@trustarc/fonts/fonts/SourceSansPro-Italic.woff2") format("woff2"), url("~@trustarc/fonts/fonts/SourceSansPro-Italic.woff") format("woff"), url("~@trustarc/fonts/fonts/SourceSansPro-Italic.ttf") format("truetype"), url("~@trustarc/fonts/fonts/SourceSansPro-Italic.svg#Italic") format("svg");
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: "TrustArc";
  src: url("~@trustarc/fonts/fonts/TrustArc.eot");
  src: url("~@trustarc/fonts/fonts/TrustArc.eot?") format("eot"), url("~@trustarc/fonts/fonts/TrustArc.woff2") format("woff2"), url("~@trustarc/fonts/fonts/TrustArc.woff") format("woff"), url("~@trustarc/fonts/fonts/TrustArc.ttf") format("truetype"), url("~@trustarc/fonts/fonts/TrustArc.svg#TrustArc") format("svg");
  font-style: normal;
  font-weight: normal;
}
.ta {
  display: inline-block;
  font: normal normal normal 14px/1 TrustArc;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ta-fullscreen:before {
  content: "\e003";
}
.ta-edit:before {
  content: "\e004";
}
.ta-filter:before {
  content: "\e005";
}
.ta-upload:before {
  content: "\e006";
}
.ta-undo:before {
  content: "\e007";
}
.ta-redo:before {
  content: "\e008";
}
.ta-warning:before {
  content: "\e009";
}
.ta-check:before {
  content: "\e010";
}
.ta-plus:before {
  content: "\e011";
}
.ta-minus:before {
  content: "\e012";
}
.ta-hand:before {
  content: "\e013";
}
.ta-trash:before {
  content: "\e014";
}
.ta-close:before {
  content: "\e015";
}
.ta-warning-triangle:before {
  content: "\e016";
}
.ta-check-circle:before {
  content: "\e017";
}
.ta-bell-circle:before {
  content: "\e018";
}
.ta-info-circle:before {
  content: "ⓘ";
}
.ta-tiles:before {
  content: "\e019";
}
.ta-warning-circle-outline:before {
  content: "\e01f";
}
.ta-warning-circle:before {
  content: "\e020";
}
.ta-download:before {
  content: "\e021";
}
.ta-question-circle:before {
  content: "\e023";
}
.ta-chevron-down:before {
  content: "\e025";
}
.ta-chevron-up:before {
  content: "\e026";
}
.ta-chevron-right:before {
  content: "\e027";
}
.ta-chevron-left:before {
  content: "\e028";
}
.ta-ellipsis:before {
  content: "⋯";
}
.ta-bell:before {
  content: "\e031";
}
.ta-print:before {
  content: "\e030";
}

.tePopover {
  display: block !important;
  will-change: none !important;
  top: 0px !important;
  right: 0px !important;
  transform: none !important;
  left: auto !important;
  margin-top: 44px !important;
}
.tePopover .popover-arrow.arrow {
  display: none;
}
.tePopover .popover-content {
  padding: 8px 0;
}
.tePopover .popover-content form {
  padding: 0 7px;
}

ng-dropdown-panel.modalNgSelect {
  z-index: 999999;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}