.md-datepicker-calendar-pane{
    z-index: 1200;
}

::ng-deep .cdk-overlay-container{
    z-index: 1200 !important;
}

.md-datepicker-fill-width .md-datepicker-input-container{
    width: 70%;
}