.row {
  margin-left: -10px;
  margin-right: -10px;
}
.col-xs-1 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-xs-2 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-xs-3 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-xs-4 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-xs-5 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-xs-6 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-xs-7 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-xs-8 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-xs-9 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-xs-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-xs-11 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-xs-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-sm-1 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-sm-2 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-sm-3 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-sm-4 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-sm-5 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-sm-6 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-sm-7 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-sm-8 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-sm-9 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-sm-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-sm-11 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-sm-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-md-1 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-md-2 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-md-3 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-md-4 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-md-5 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-md-6 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-md-7 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-md-8 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-md-9 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-md-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-md-11 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-md-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-lg-1 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-lg-2 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-lg-3 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-lg-4 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-lg-5 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-lg-6 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-lg-7 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-lg-8 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-lg-9 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-lg-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-lg-11 {
  padding-left: 10px;
  padding-right: 10px;
}
.col-lg-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.row-absolute {
  position: absolute;
  width: 100%;
  top: 80px;
  left: 0;
}