/* 
    Author: William Donayre Jr.
*/

$delay: .9s;
$offsetY: 30px;

/*===================*/


/* FADE DEFINITION   */


/*===================*/

%fade {
    -webkit-transition: opacity $delay linear, -webkit-transform $delay ease;
    transition: opacity $delay linear, -webkit-transform $delay ease;
    transition: opacity $delay linear, transform $delay ease;
    transition: opacity $delay linear, transform $delay ease, -webkit-transform $delay ease;
}

%fade-in {
    @extend %fade;
    opacity: 0;
    .a-wp.a-execute & {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}


/*===================*/

//delay
@include delay(20);

/*===================*/

.a-fade-up {
    @extend %fade-in;
    -webkit-transform: translateY($offsetY);
    -ms-transform: translateY($offsetY);
    transform: translateY($offsetY);
}

.a-fade-down {
    @extend %fade-in;
    -webkit-transform: translateY(-$offsetY);
    -ms-transform: translateY(-$offsetY);
    transform: translateY(-$offsetY);
}