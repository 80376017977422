.headerpanel {
  background-color: $color_cape_cod_approx;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.headerwrapper {
  padding: 10px 15px 5px;
  margin: auto;
  height: 70px;
  .logo {
    margin: 0 20px 0 0;
    width: 50px;
    height: 52px;
    background: $url_0 no-repeat 0 0;
  }
  &:after {
    clear: both;
    display: block;
    content: '';
  }
}

.headerpanel-white {
  background-color: $white;
  border-bottom: 1px solid $color_alto_approx;
  .appshow-menu {
    border-left-color: $color_alto_approx;
  }
  .profile-photo {
    border-color: $color_alto_approx;
  }
  .headerwrapper .logo {
    background-image: $url_1;
  }
  .nav-top > li {
    &:before {
      border-left-color: $color_celeste_approx;
    }
    > a {
      color: $color_storm_dust_approx;
      &:hover {
        color: $color_mine_shaft_approx;
      }
      &:focus {
        color: $color_mine_shaft_approx;
      }
      &:active {
        color: $color_mine_shaft_approx;
      }
    }
    &.active {
      > a {
        color: $color_mantis_approx;
      }
      &.open > a.dropdown-toggle {
        color: $color_mantis_approx;
      }
    }
    &.btn-group.open > a {
      color: $color_mine_shaft_approx;
    }
    > .dropdown-menu {
      padding-top: 0;
      > li > a:hover {
        background-color: $color_tundora_approx;
        color: $white;
      }
    }
  }
  .head-right .user-menu {
    .btn {
      color: $color_mine_shaft_approx;
    }
    .dropdown-menu {
      padding-top: 0;
    }
  }
}