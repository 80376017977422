.nav-tabs {
  background: $color_alto_approx;
  border: 0;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px 3px 0 0;
  > li {
    > a {
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 3px 3px 0 0;
      border: 0;
      padding: 14px 20px;
      color: $color_storm_dust_approx;
      &:hover {
        background: none;
        color: $color_mine_shaft_approx;
      }
    }
    &.active > a {
      background-color: $white;
      border: 0;
      &:hover {
        background-color: $white;
        border: 0;
      }
      &:focus {
        background-color: $white;
        border: 0;
      }
    }
  }
  &.nav-justified > li > a {
    border-bottom: 0;
  }
}

.nav-dark {
  background: $color_ebony_clay_approx;
  > li > a {
    color: $color_mountain_mist_approx;
    &:hover {
      color: $white;
    }
  }
}
.nav {
  .dropdown {
    > a {
      font-weight: bold;
    }
    .caret {
      border-top-color: $color_storm_dust_approx;
    }
  }
  .dropdown-menu {
    margin-top: -2px;
  }
  .open > a {
    @extend %extend_16;
    &:hover {
      @extend %extend_16;
    }
    &:focus {
      @extend %extend_16;
    }
  }
  > li.divider {
    border-top: 1px solid $color_alto_approx;
    height: 1px;
    margin: 10px 0;
  }
}

.nav-line {
  border-bottom: 5px solid $color_gallery_approx;
  height: 40px;
  margin-bottom: 20px;
  li {
    a {
      padding: 7px 20px;
      color: $color_mine_shaft_approx;
      font-weight: 500;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0;
      &:hover {
        background: none;
        border-bottom: 5px solid $color_celeste_approx;
      }
    }
    &.active a {
      @extend %extend_17;
      &:active {
        @extend %extend_17;
      }
      &:focus {
        @extend %extend_17;
      }
      &:hover {
        background: none;
        color: $color_boston_blue_approx;
      }
    }
  }
}

.nav-top {
  margin: 15px 0 0;
  padding: 0;
  > li {
    display: inline-block;
    float: left;
    font-size: 12px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: 400;
    &:before {
      position: absolute;
      top: 3px;
      left: 0;
      width: 1px;
      height: 15px;
      border-left: 1px dotted $color_storm_dust_approx;
      content: '';
    }
    &:first-child::before {
      display: none;
    }
    &.btn-group {
      margin: 0;
      &.open > a {
        @extend %extend_18;
      }
    }
    > a {
      color: $color_celeste_approx;
      padding: 0 0 24px 0;
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        @extend %extend_18;
      }
      &:focus {
        @extend %extend_18;
      }
      &:active {
        @extend %extend_18;
      }
      &.dropdown-toggle {
        padding-right: 15px;
        &:after {
          content: '\f107';
          position: absolute;
          right: 0;
          top: 0;
          font-family: $font_2;
          opacity: 0.5;
        }
      }
    }
    &.active {
      > a {
        color: $color_mantis_approx;
      }
      &.open > a.dropdown-toggle {
        color: $color_mantis_approx;
      }
    }
    > .dropdown-menu {
      margin: 0 0 0 10px;
      border-top: 0;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0 0 3px 3px;
      > li {
        font-size: 12px;
        > a:hover {
          background-color: $color_cape_cod_approx;
          color: $white;
        }
      }
      .divider {
        margin: 5px 0;
      }
    }
  }
  > .open > a {
    font-weight: 400;
    &:hover {
      font-weight: 400;
    }
    &:focus {
      font-weight: 400;
    }
  }
}

@mixin nav-left-menu-item($fontSize, $padding) {
  background-color: $color_sky_blue;
  color: $white;
  font-size: $fontSize;
  &:hover {
    background-color: $color_ulta_light_sky_blue;
    color: $color_sky_blue;
  }
}

.nav-leftmenu > li {
  > a {
    @include nav-left-menu-item(16px, 10px 15px);
    cursor: pointer;
    height: 53px;
    padding: 15px 0px 18px 20px;
  }
  &.active > a {
    background-color: $color_ulta_light_sky_blue;
    color: $color_sky_blue;
    &:after {
      position: absolute;
      top: 15px;
      right: 12px;
      content: '\f105';
      font-family: $font_2;
      font-size: 24px;
      color: $color_sky_blue;
      opacity: .5;
    }
  }
  &.active > .submenu {
    display: block;
  }

  > .submenu {
    display: none;
    margin-top: 0;
    > li {
      > a {
        @include nav-left-menu-item(16px, 10px);
        border-radius: 0;
        padding: 15px 0px 18px 40px;
        cursor: pointer;
      }
      &.active {
        // border-right: 2px solid #7cca4c;
        > a {
          background-color: $color_ulta_light_sky_blue;
          color: $color_sky_blue;
        }
      }
    }
  }
}


.nav-truste > li {
  > a {
    font-weight: 500;
    color: $color_abbey_approx;
    padding: 11px 15px;
    &:hover {
      background-color: $color_mystic_approx;
    }
    .badge {
      margin-top: 2px;
    }
  }
  &.active > a {
    background-color: $color_nevada_approx;
    &:hover {
      background-color: $color_abbey_approx;
    }
    &:active {
      background-color: $color_abbey_approx;
    }
    &:focus {
      background-color: $color_abbey_approx;
    }
    .badge {
      color: $color_nevada_approx;
      margin-top: 1px;
    }
  }
}

.nav-stacked > li + li {
  margin: 0;
}

.nav-horizontal-link li {
  display: inline-block;
  float: left;
  margin-left: 20px;
  &:first-child {
    margin-left: 0;
  }
  a {
    padding: 0;
    margin: 5px 0;
    &:hover {
      background: none;
    }
  }
}

.nav-leftpanel {
  > li {
    margin-bottom: 2px;
    + li {
      margin-bottom: 2px;
    }
    > a {
      background-color: $color_alabaster_approx;
      color: $color_mountain_mist_approx;
      font-size: 14px;
      padding: 15px 15px 15px 55px;
      font-weight: 400;
      cursor: default;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 3px;
      &:hover {
        background-color: $color_alabaster_approx;
      }
      &:before {
        position: absolute;
        top: 13px;
        left: 15px;
        border: 2px solid $color_mountain_mist_approx;
        width: 26px;
        height: 26px;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 50px;
        padding: 2px 0 0 7px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
      }
    }
    &.done {
      > a {
        background-color: $color_mantis_approx;
        color: $white;
        &:before {
          border-width: 0;
          content: '\f058';
          font-family: $font_2;
          font-size: 29px;
          font-weight: 100;
          padding-left: 0;
          top: 15px;
        }
      }
      &.active > a:after {
        color: $white;
      }
    }
    &:first-child > a:before {
      content: '1';
    }
    &:nth-child(2) > a:before {
      content: '2';
    }
    &:nth-child(3) > a:before {
      content: '3';
    }
    &.active > a {
      background-color: $color_mystic_approx;
      color: $color_mine_shaft_approx;
      &:before {
        border-color: $color_mine_shaft_approx;
      }
      &:after {
        position: absolute;
        top: 15px;
        right: 15px;
        content: '\f105';
        font-family: $font_2;
        font-size: 28px;
        color: $color_celeste_approx;
      }
    }
  }
  &.nonumber > li > a {
    padding-left: 15px;
    &:before {
      display: none;
    }
  }
}

.nav-pills > li > a {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px;
}
.nav-title {
  text-transform: uppercase;
  font-weight: 400;
  color: $color_suva_gray_approx;
  font-size: 11px;
  margin: 5px 0 15px;
}
