.select2-container-multi {
  .select2-choice {
    @extend %extend_20;
    &:focus {
      outline: none;
    }
    .select2-arrow {
      @extend %extend_21;
      b {
        background: $url_2 no-repeat 0 15px;
      }
    }
  }
  .select2-choices {
    @extend %extend_20;
    &:focus {
      outline: none;
    }
    .select2-arrow {
      @extend %extend_21;
      b {
        background: $url_2 no-repeat 0 15px;
      }
    }
    .select2-search-choice {
      @extend %extend_22;
    }
    .select2-search-field input {
      padding: 3px 5px;
    }
  }
  &.sel2-sm {
    min-width: 150px;
    .select2-choice {
      padding: 1.5px 0 1.5px 8px;
      .select2-arrow b {
        background-position: 0 12px;
      }
    }
    .select2-choices {
      padding: 1.5px 0 1.5px 8px;
      .select2-arrow b {
        background-position: 0 12px;
      }
    }
  }
  &.select2-container-active {
    .select2-choice {
      border: 1px solid $color_mountain_mist_approx;
      border-bottom: 1px solid $color_mountain_mist_approx;
    }
    .select2-choices {
      border: 1px solid $color_mountain_mist_approx;
      border-bottom: 1px solid $color_mountain_mist_approx;
    }
  }
}