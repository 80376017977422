.access-logic-outer {
    background: #f9f9f9;
    padding: 12px;
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid #f5f5f5;
    .access-logic-group {
        background: white;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        padding: 15px;
        margin-bottom: 10px;
        .access-logic-label {
            font-size: 12px;
            font-weight: bold;
            color: #000;
            &+.access-logic-field {
                .select-controls {
                    .btn-acl-remove {
                        display: none;
                    }
                }
            }
        }
        .access-logic-field {
            overflow: auto;
            margin-bottom: 5px;
            .select-tag-group {
                width: 65%;
                float: left;
                padding-right: 3px;
                &.has-error .form-control {
                    padding: 0px;
                    .select2-container .select2-choice {
                        border: none;
                    }
                };
            }
            .select-logic {
                width: 20%;
                float: left;
                padding-left: 3px;
            }
            .select-controls {
                width: 15%;
                float: left;
                button {
                    border: none;
                    font-size: 18px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    outline: none;
                }
            }
            //temp
            select {
                height: 30px;
            }

        }
    }
    //logic group operator
    .access-logic-group-operator {
        display: inline-block;
        margin: 0 0 10px 0;
        select {
            height: 30px;
            min-width: 80px;
        }
    }
}

.access-logic-preview {
    .access-logic-value {
        padding: 12px;
        background: #f9f9f9;
        font-size: 12px;
        color: #6b6b6b;
    }
}