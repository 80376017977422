.table {
  background: none;
  border-bottom: 2px solid $color_gallery_approx;
  border: none;
  th {
    font-weight: 500;
  }
  &.table-truste {
    border: 1px solid $color_alto_approx;
    > thead > tr > th {
      border-top: 1px solid $color_alto_approx !important;
      border-color: $color_alto_approx;
      border-bottom-width: 1px;
      padding-top: 11px;
      padding-bottom: 11px;
    }
    > tbody > tr > td {
      .btn-white {
        padding: 0;
        border: 0;
        background: none;
      }
      &:first-child > a {
        font-size: 11px;
        color: $color_mountain_mist_approx;
        width: 16px;
        text-align: center;
      }
      .progress-bar {
        line-height: normal;
      }
    }
  }
  &.borderless {
    tr {
      border: none;
    }
    td {
      border: none;
    }
    th {
      border: none;
    }
  }
  &.profile {
    margin-bottom: 50px;
    th {
      width: 150px;
      text-align: right;
      font-size: 12px;
      color: $color_silver_chalice_approx;
      + td {
        width: 600px;
        * {
          color: $color_tuatara_approx;
        }

        .label {
          color: white;
        }

        .progress-bar {
          color: white;
          line-height: normal;
        }

        .label-primary {
          color: white;
        }
      }
    }
  }
  > thead > tr > th {
    border-color: $color_mystic_approx;
    padding-top: 12px;
    padding-bottom: 12px;
    color: $color_tundora_approx;
    vertical-align: baseline;
  }
  thead > tr {
    > th {
      border-color: $color_gallery_approx;
    }
    > td {
      border-color: $color_gallery_approx;
    }
  }
  tbody > tr {
    > th {
      border-color: $color_gallery_approx;
    }
    > td {
      border-color: $color_gallery_approx;
    }
  }
  tfoot > tr {
    > th {
      border-color: $color_gallery_approx;
    }
    > td {
      border-color: $color_gallery_approx;
    }
  }
  &.table-primary > thead > tr > th {
    border-bottom: 0;
    color: $white;
  }
  &.table-success > thead > tr > th {
    border-bottom: 0;
    color: $white;
  }
  &.table-danger > thead > tr > th {
    border-bottom: 0;
    color: $white;
  }
  &.table-warning > thead > tr > th {
    border-bottom: 0;
    color: $white;
  }
  &.table-info > thead > tr > th {
    border-bottom: 0;
    color: $white;
  }
  &.table-dark > thead > tr > th {
    border-bottom: 0;
    color: $white;
  }
}

.table-option {
  background: $color_black_haze_approx;
  padding: 6px 15px;
  border: 1px solid $color_alto_approx;
  border-bottom: 0;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px 3px 0 0;
}

.table-multiple-check {
  margin: 0;
  > thead > tr > th {
    font-weight: 500;
    color: $color_storm_dust_approx;
    font-size: 13px;
    text-transform: uppercase;
    padding-bottom: 0;
    border-bottom: 0;
  }
  > tbody > tr > td {
    font-size: 13px;
  }
}

.table-bordered {
  &.table-primary > thead > tr > th {
    border-color: $white_20;
  }
  &.table-success > thead > tr > th {
    border-color: $white_20;
  }
  &.table-warning > thead > tr > th {
    border-color: $white_20;
  }
  &.table-danger > thead > tr > th {
    border-color: $white_20;
  }
  &.table-info > thead > tr > th {
    border-color: $white_20;
  }
  &.table-dark > thead > tr > th {
    border-color: $white_20;
  }
}

.table-default > thead > tr > th {
  background-color: $color_mystic_approx;
}
.table-primary > thead > tr > th {
  background-color: $color_boston_blue_approx;
}
.table-success > thead > tr > th {
  background-color: $color_mountain_meadow_approx;
}
.table-warning > thead > tr > th {
  background-color: $color_casablanca_approx;
}
.table-danger > thead > tr > th {
  background-color: $color_chestnut_rose_approx;
}
.table-info > thead > tr > th {
  background-color: $color_viking_approx;
}
.table-dark > thead > tr > th {
  background-color: $color_ebony_clay_approx;
}

.table-striped > tbody > tr:nth-child(2n + 1) {
  > td {
    background-color: $color_wild_sand_approx;
  }
  > th {
    background-color: $color_wild_sand_approx;
  }
}


.table-alerts {
  margin-bottom: 0;
  border: 0;
  > thead > tr > th {
    padding: 7px 15px;
    font-weight: 500;
    border-bottom: 2px solid $color_celeste_approx;
    background-color: $color_black_haze_approx;
  }
  .ckbox input[type="checkbox"]:checked + label:after {
    top: 3px;
  }
  > tbody > tr {
    > td {
      border-bottom: 1px solid $color_mercury_approx;
      padding: 8px 15px;
      line-height: normal;
    }
    &:last-child > td {
      border-bottom: 0;
    }
  }
}

.collapsible-row .collapsible-column {
  background-color: #f5f5f5;
  border: none;
  margin-bottom: 5px;
  padding: 0 8px 0;

  .collapse-content {
    float: left;
    width: 50%;
    word-break: break-all;
  }
}
