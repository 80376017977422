.thumbnail {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px;
    padding: 5px;
}

.title-header {
    background-color: $color_mystic_approx;
    border-bottom: 1px solid $color_alto_approx;
}

.mph-wrapper {
    margin: auto;
    padding: 0 20px;
}

.page-title {
    margin: 8px 0 5px;
    font-weight: 400;
    color: $color_tundora_approx;
}

.page-subtitle {
    color: $color_tapa_approx;
}

.mainwrapper {
    background-color: $white;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px;
    position: relative;
    min-height: 600px;
}

.smallcapstitle {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: 1px dotted $color_alto_approx;
}

.profile-photo {
    border: 2px solid $color_storm_dust_approx;
    display: inline-block;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 50px;
    margin-right: 10px;
    img {
        width: 35px;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 50px;
        padding: 2px;
    }
}

.appshow-menu {
    margin-top: 7px;
    padding: 0 0 0 15px;
    border-left: 1px solid $color_fuscous_gray_approx;
    display: inline-block;
    float: left;
}

.tab-pane address:last-child {
    margin-bottom: 0;
}

.panel-group-sm .panel>.panel-heading {
    padding: 7px 10px;
}

.formfooter:after {
    clear: both;
    display: block;
    content: '';
}

.noti-menu.open .head-notif {
    @extend %extend_19;
}

.sel {
    width: 100% !important;
}

.build-info {
    padding: 5px 5px 0;
    width: 100%;
    position: fixed;
    bottom: 0;
    font-size: 10px;
    z-index: 99999;
    background-color: white;
}

.tags-list label {
    width: 150px;
    text-align: right;
    font-size: 12px;
    color: $color_silver_chalice_approx;
    padding-right: 5px;
    margin-right: 5px;
}

.form-inline .form-group {
  min-width: 120px;
}
