.open {
  .dropdown-toggle {
    &.btn-default {
      @extend %extend_2;
    }
    &.btn-primary {
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: none;
    }
    &.btn-success {
      background-color: $color_mantis_approx;
      border-color: $color_sushi_approx;
    }
    &.btn-white {
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: none;
      background: $color_black_haze_approx;
    }
    &.btn-warning {
      @extend %extend_3;
      background-image: none;
    }
    &.btn-danger {
      @extend %extend_4;
      background-image: none;
    }
    &.btn-info {
      @extend %extend_5;
      background-image: none;
    }
  }
  > .dropdown-toggle {
    &.btn-default {
      @extend %extend_23;
      background-image: none;
    }
    &.btn-primary {
      @extend %extend_24;
      background-image: none;
    }
    &.btn-success {
      @extend %extend_25;
      background-image: none;
    }
    &.btn-info {
      @extend %extend_26;
      background-image: none;
    }
    &.btn-warning {
      @extend %extend_27;
      background-image: none;
    }
    &.btn-danger {
      @extend %extend_28;
      background-image: none;
    }
  }
}