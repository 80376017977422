.ui18 {
    i,
    p,
    div,
    strong,
    b,
    label,
    form {
        font-size: 14px;
        color: #333;
    }
}