.badge {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 2px;
  font-weight: normal;
  font-size: 11px;
  vertical-align: middle;
  display: inline-block;
  padding: 4px 6px;
}
.badge-warning {
  background-color: $color_tangerine_approx;
  background-color: $color_casablanca_approx;
}
.badge-success {
  background-color: $color_forest_green_approx;
  background-color: $color_mountain_meadow_approx;
}

.badge-primary {
  background-color: $color_boston_blue_approx;
}
.badge-danger {
  background-color: $color_chestnut_rose_approx;
}
.badge-info {
  background-color: $color_viking_approx;
}