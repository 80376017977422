
.breadcrumb-wrapper {
  background: $color_black_haze_approx;
  margin: auto;
  padding: 7px 0;
  border-bottom: 1px solid $color_gallery_approx;
  .breadcrumb {
    background: none;
    padding: 0 15px;
    font-size: 12px;
    margin: auto;
    > li {
      color: $color_mine_shaft_approx;
      > a {
        color: $color_storm_dust_approx;
      }
    }
    > li + li::before {
      content: "/";
    padding: 0 5px;
    color: #ccc;
    }
  }
}
