.modal-dialog {
  border: 0;
  .modal-footer {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0 0 3px 3px;
  }
}
.modal-header {
  background-color: $color_cape_cod_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px 3px 0 0;
  padding: 20px;
  border-bottom: 0;
  .close {
    margin-top: 0;
    color: $white;
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: none;
    border: 2px solid $white;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 50px;
    line-height: 0;
    width: 30px;
    height: 30px;
    padding: 0 0 3px 0;
    font-weight: 500;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.2s ease-out 0s;
  }
  .desc {
    color: $white;
    margin: 0;
    opacity: 0.75;
  }
}
.modal-content {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 5px 6px $black_20;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px;
  border: 0;
  background: none;
}
.modal-title {
  font-size: 20px;
  color: $white;

  .truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.modal-body {
  padding: 20px;
  background-color: $white;
}
.modal-footer {
  background: $color_black_haze_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0 0 3px 3px;
}
.modal-backdrop {
  z-index: 999999 !important;
  &.in {
    opacity: 0.30;
  }
}

.modal {
  z-index: 999999 !important;
}

@media (min-width: 768px){
  .modal-sm{
    width: 310px;
  }
}

