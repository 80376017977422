.popover {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 2px;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 1px 1px 2px $black_5;
  padding: 0;
}
.popover-title {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 2px 2px 0 0;
  font-size: 13px;
  border-color: $color_alto_approx;
}