bs-toast,
bs-modal-backdrop,
bs-modal-window,
bs-alert,
bs-tooltip-window,
bs-popover-window {
  z-index: 999999 !important;
}

.modal-open .modal {
  width: auto;
}

bs-datepicker-container, bs-daterangepicker-container {
  z-index: 999999;
}
