//configuration variables
$delayMultiplier: 150;

/*=======================*/

@mixin vendorPrefix($property, $value) {
    $vendors: (-webkit, -moz, -o, -ms);
    @for $pIndex from 1 through length($vendors) {
        #{nth($vendors,$pIndex)}-#{$property}: #{$value};
    }
    #{$property}:#{$value};
}

@mixin delay($num) {
    @for $i from 1 through $num {
        .a-delay-#{$i} {
            $delayValue: ($delayMultiplier * $i);
            $delayUnit: ms;
            @if $delayValue>=1000 {
                $delayValue: $delayValue / 1000;
                $delayUnit: s;
            }
            @include vendorPrefix(transition-delay, #{$delayValue}#{$delayUnit} !important);
        }
    }
}