.ui18 {
    $buttonSize: 14px;
    $minWidth: 94px;
    $basePadding: 6px;
    $baseLineHeight: 16px;
    .btn {
        font-size: $buttonSize;
        min-width: $minWidth;
        padding: $basePadding $basePadding*3;
        line-height: $baseLineHeight;
        font-family: $uiFontFamily;
        &.btn-primary {
            background: #00B4DD;
            border: 1px solid #00B4DD;
            color: #ffffff;
            &:hover {
                background: #0C93B6;
                border: 1px solid #0C93B6;
                color: #ffffff;
            }
            &:active {
                background: #33C3E4;
                border: 1px solid #33C3E4;
                color: #ffffff;
                &:focus {
                    outline: none;
                }
            }
            &:disabled {
                background: #E0E0E0;
                border: 1px solid #C2C2C2;
                color: #C2C2C2;
            }
        }
        &.btn-secondary {
            background: #ffffff;
            border: 1px solid #BEBEBE;
            color: #333333;
            &:hover {
                background: #ffffff;
                border: 1px solid #0C93B6;
                color: #333333;
            }
            &:active {
                background: #D1D1D1;
                border: 1px solid #33C3E4;
                color: #333333;
                &:focus {
                    outline: none;
                }
            }
            &:disabled {
                background: #E0E0E0;
                border: 1px solid #C2C2C2;
                color: #C2C2C2;
            }
        }
        &.btn-flat {
            background: none;
            border: 1px solid none;
            color: #0C93B6;
            &:hover {
                background: none;
                border: none;
                color: #064676;
            }
            &:active {
                background: none;
                border: none;
                color: #33C3E4;
                &:focus {
                    outline: none;
                }
            }
            &:disabled {
                background: none;
                border: none;
                color: #CECECE;
            }
        }
    }
}