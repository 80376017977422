.ui18 {
    /* SIZING */
    //in bootstrap 4 - (can be removed)
    .w-100 {
        width: 100% !important;
    }
    /* DISPLAY */
    .d-block {
        display: block !important;
    }
}