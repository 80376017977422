table {
    tr {
        &.blank-table {
            text-align: center;
            td {
                padding: 50px 0;
            }
            label {
                font-size: 16px;
                margin-bottom: 10px;
            }
            button {
                background: white !important;
                padding: 8px;
                min-width: 150px;
                border: 2px solid #aaa;
                border-radius: 3px;
                margin-top: 16px;
            }
        }
    }
}