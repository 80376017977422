.naked-dropdown {
    .label {
        font-size: 12px;
        color: #343434;
        padding-left: 0;
    }
    .dropdown {
        button {
            border: none;
            font-size: 18px;
            padding: 0;
            background: none !important;
            font-weight: normal;
        }
    }
}